import views from '../views'

const createFeatureCollection = features => ({
  type: 'FeatureCollection',
  bbox: [0.16938650670010755, 40.51492955510811, 3.3355556288917163, 42.88393698626089],
  features: features.filter(f => f.properties.checked)
})

const createLayers = state => {
  const { view, geo } = state
  const { events, layers, transform } = views[view]

  if (!layers || !layers.length) {
    throw new Error(`View ${view} is not related to any layers`)
  }

  const newLayers = []

  for (let i = 0; i < layers.length; i++) {
    const { config, ...paint } = layers[i]
    const layer = {}
    const needTransform = config && config.transform && typeof transform === 'function'
    const features = needTransform ? transform(layers[i].id, state) : [...geo.features]

    layer.data = createFeatureCollection(features)
    layer.paint = { ...paint }

    if (events) {
      layer.events = { ...events }
    }

    newLayers.push(layer)
  }

  return newLayers
}

export default createLayers

// import layers from '../layers'

// function createLayer ({ transformProperties }, source, state) {
//   if (!source.data) {
//     return null
//   }

//   const baseLayer = createFeatureCollection()

//   if (transformProperties && typeof transformProperties === 'function') {
//     baseLayer.features = transformProperties(source, state)
//   }

//   // baseLayer.features = baseLayer.features.filter(e => e.properties.districte === '06')

//   return baseLayer
// }

// export default state => {
//   const getSource = id => sources.find(s => s.id === id)

//   return layers
//     // .filter(layer => layer.config.visible)
//     .map(layer => {
//       const transformedLayer = { ...layer }
//       const _layer = { config: layer.config }
//       const sourceData = {
//         id: layer.config.source,
//         data: createLayer(layer, getSource(layer.config.id), state),
//         type: 'geojson'
//       }

//       if (sourceData.data) {
//         _layer.sourceData = sourceData
//       }

//       if (layer.on) {
//         _layer.on = { ...layer.on }
//       }

//       return _layer
//     })
// }

export default [
  {
    id: 'TOC-partit-linies',
    type: 'line',
    source: 'linies-source',
    paint: {
      'line-color': ['case',
        ['boolean', ['feature-state', 'hover'], false], 'yellow',
        ['boolean', ['feature-state', 'selected'], false], 'yellow',
        '#ccc'
      ],
      'line-width': 1
    }
  },
  {
    config: {
      transform: true,
      events: false
    },
    id: 'TOC-partit',
    type: 'circle',
    source: 'partit-source',
    paint: {
      'circle-color': ['get', 'color'],
      'circle-radius': ['get', 'radius'],
      'circle-blur': 0,
      'circle-stroke-width': 1,
      'circle-stroke-color': '#ddd'
    }
  }
]

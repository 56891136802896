import React, { Component, createContext } from 'react'
import url from 'url'
import bbox from '@turf/bbox'
import localforage from 'localforage'

import initialState from '../state'
import createLayers from '../lib/createLayers'
import reducer from './reducer'
import { formatCodi } from '../lib/utils'
import { parseGeo, parsePartits, parseEleccions, filter, getCenter } from './utils'
import exportMap from '../lib/exportMap'
import api from '../lib/api'

localforage.config({
  driver: localforage.LOCALSTORAGE,
  name: 'TOC',
  version: 1.0,
  storeName: 'app'
})

export const Context = createContext()

class Store extends Component {
  state = {
    firstMount: true,
    ...initialState,
    dispatch: action => this.setState(state => reducer(state, action))
  }

  actions = {
    toggleAny: e => this.state.dispatch({ type: 'TOGGLE_ANY', any: e.target.value }),
    toggleMunicipi: municipi => this.state.dispatch({ type: 'TOGGLE_MUNICIPI', municipi }),
    changeLevel: e => this.state.dispatch({ type: 'TOGGLE_LEVEL', level: e.target.value }),
    toggleTheme: () => this.state.dispatch({ type: 'TOGGLE_THEME' }),
    togglePartit: ({ target: { value: partit } }) => this.state.dispatch({ type: 'TOGGLE_PARTIT', partit }),
    toggleGeo: ({ target: { value: mundissec } }) => this.state.dispatch({ type: 'TOGGLE_GEO', mundissec }),
    toggleAllGeo: () => this.state.dispatch({ type: 'TOGGLE_ALL_GEO' }),
    toggleNoneGeo: () => this.state.dispatch({ type: 'TOGGLE_NONE_GEO' }),
    toggleAllPartits: () => this.state.dispatch({ type: 'TOGGLE_ALL_PARTITS' }),
    toggleNonePartits: () => this.state.dispatch({ type: 'TOGGLE_NONE_PARTITS' }),
    toggleView: e => this.state.dispatch({ type: 'TOGGLE_VIEW', view: e.target.value }),
    toggleShowLegend: () => this.setState({ showLegend: !this.state.showLegend }),
    toggleShowMap: () => this.state.dispatch({ type: 'TOGGLE_SHOWMAP' }),
    toggleEleccio: e => this.state.dispatch({ type: 'TOGGLE_ELECCIO', eleccio: e.target.value }),
    toggleEleccioAny: e => this.state.dispatch({ type: 'TOGGLE_ELECCIO_ANY', any: e.target.value })
  }

  exportMap = async () => {
    const { dispatch, mapbox } = this.state
    dispatch({ type: 'TOGGLE_LOADING' })
    await exportMap(mapbox)
    dispatch({ type: 'TOGGLE_LOADING' })
  }

  fetch = async () => {
    const { selectedPartits, eleccio, municipi, level, view } = this.state

    municipi.codi = formatCodi(municipi.codi)

    // if (level === 'municipis' && municipi) {
    //   return this.setState({
    //     level: 'districtes',
    //     municipi
    //   })
    // }

    // 1- Fetch Data
    const [{ partits, ...rest }, geo] = await Promise.all([
      api('dades', { level, municipi: municipi ? municipi.nom : null }),
      api('geo', { level, municipi: municipi ? municipi.codi : null })
    ])

    return {
      geo: parseGeo(geo),
      partits,
      selectedPartits: parsePartits(partits[eleccio], view, selectedPartits),
      ...rest
    }
  }

  compute = async (needFetch = false) => {
    try {
      let partialState = {}

      if (needFetch) {
        partialState = await this.fetch()
        partialState.map = {
          center: getCenter(partialState.geo),
          bounds: bbox(partialState.geo)
        }
      }

      // 2- Compute data based on user preferences
      const { paintedPartits, filteredData } = filter({ ...this.state, ...partialState })
      partialState.filteredData = filteredData
      partialState.paintedPartits = paintedPartits

      // 3- Create layers from computed data
      partialState.layers = createLayers({ ...this.state, ...partialState })

      return partialState
    } catch (err) {
      throw err
    }
  }

  process = async needFetch => {
    const { firstMount, dispatch } = this.state

    dispatch({ type: 'TOGGLE_LOADING' })

    const data = await this.compute(needFetch)

    dispatch({ type: 'UPDATE_DATA', ...data })
    dispatch({ type: 'TOGGLE_LOADING' })

    if (firstMount) {
      this.setState({ firstMount: false })
    } else {
      dispatch({ type: 'TOGGLE_REPAINT' })
    }
  }

  save = async name => {
    const saved = [
      ...this.state.saved,
      {
        id: this.state.saved[this.state.saved.length - 1],
        created: new Date(),
        state: { ...this.state }
      }
    ]

    localforage.setItem('saved', saved)

    this.setState({ saved })
  }

  load = id => this.state.saved.find(map => map.id === id)

  async componentDidUpdate (prevProps, prevState) {
    const { compute, fetch } = this.state
    const needFetch = fetch && !prevState.fetch
    const needCompute = compute && !prevState.compute

    if (needCompute || needFetch) {
      await this.process(needFetch)
    }
  }

  async componentDidMount () {
    /*
     * Set initival variables either from URL as query params or from initial state
     */
    let {
      geo: selectedGeo = initialState.selectedGeo,
      level = initialState.level,
      // any = initialState.any,
      municipi: codi = initialState.municipi ? initialState.municipi.codi : null
    } = url.parse(window.location.href, true).query

    /*
     * Request municipis & eleccions to API
     */
    let { municipis, eleccions } = await api('init')

    eleccions = parseEleccions(eleccions)

    /*
     * Set municipi based on url query &municipi= or initialstate
     * We need all municipis to match from codi.
     */
    const municipi = level === 'municipis'
      ? null
      : !codi
        ? initialState.municipi
        : municipis.find(m => m.codi === Number(codi))

    // Dunno why!
    if (level === 'districtes' && selectedGeo) {
      selectedGeo = null
    }

    /**
      Get saved maps
     */
    const saved = await localforage.getItem('saved')

    let any
    let eleccio
    for (let i = 0; i < eleccions.length; i++) {
      if (eleccions[i].checked) {
        eleccio = eleccions[i].nom
        for (let j = 0; j < eleccions[i].anys.length; j++) {
          if (eleccions[i].anys[j].checked) {
            any = eleccions[i].anys[j].any
          }
        }
      }
    }

    this.setState({
      eleccions,
      municipis,
      level,
      any: Number(any),
      eleccio,
      municipi,
      selectedGeo,
      saved,
      fetch: true
    })
  }

  render () {
    const { state, actions, props: { children } } = this

    return (
      <Context.Provider value={{ ...state, ...actions, fetch: this.fetch, exportMap: this.exportMap }}>
        {children}
      </Context.Provider>
    )
  }
}

export const Consumer = Context.Consumer

export default Store

import React from 'react'
import styled from 'styled-components'
import convert from 'color-convert'

import { getDynamicSteps } from '../utils'
import { generatePaletteFromColor, formatHSL } from '../../colors'

const Step = styled.div`
  display: flex;
  width: 100%;
  background: ${({ color }) => color};
  flex-grow: 1;

  // &:first-of-type {
  //   border-radius: 4px 0 0 4px;
  // }

  // &:last-of-type {
  //   border-radius: 0 4px 4px 0;
  // }
`

const StepWrapper = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
  height: 40px;
  font-size: 10px;
  text-align: center;
`

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`

const Legend = ({ partits, filteredData }) => {
  const threshold = Math.max(...filteredData.map(r => (r.partits.length && r.partits[0].percentatgeValids) || 0))
  const steps = getDynamicSteps(threshold)
  const partit = partits.find(partit => partit.checked)
  const colors = generatePaletteFromColor(convert.rgb.hsl(partit.color))

  return (
    <Wrapper>
      {colors.map((color, i) =>
        <StepWrapper key={`legend-partit2-${i}`}>
          <Step color={formatHSL(color)} />
          {i === 0 ? '<=' : ''}
          {i + 1 === colors.length ? '>=' : ''}
          {steps[i]}%
        </StepWrapper>
      )}
    </Wrapper>
  )
}

export default Legend

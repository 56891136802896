import fetch from 'universal-fetch'
import html2canvas from 'html2canvas'

const exportMap = async map => {
  Object.defineProperty(window, 'devicePixelRatio', { get: () => 300 / 96 })
  const legendEl = document.querySelector('#legend')
  const legendCanvas = legendEl ? await html2canvas(legendEl) : null
  const mapCanvas = map.getCanvas()
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  ctx.globalCompositeOperation = 'destination-over'
  ctx.imageSmoothingEnabled = true

  ctx.fillStyle = 'white'
  ctx.fillRect(0, 0, canvas.width, canvas.height)

  canvas.width = mapCanvas.width
  canvas.height = mapCanvas.height
  ctx.drawImage(mapCanvas, 0, 0, mapCanvas.width, mapCanvas.height)

  if (legendCanvas) {
    ctx.drawImage(legendCanvas, 10, 10, legendCanvas.width, legendCanvas.height, 10, 10, legendCanvas.width / 2, legendCanvas.height / 2)
  }

  const image = canvas.toDataURL('image/png')
  const imageP = await fetch(image)
  const blob = await imageP.blob()
  window.open(URL.createObjectURL(blob), '_BLANK')
}

export default exportMap

import React from 'react'
import styled from 'styled-components'

import { Consumer } from '../../context'
import Toggle from './Toggle'
import Levels from './Levels'
import Autocomplete from './Autocomplete'
import GPanel from '../GPanel'
import Block from './Block'
import Partits from './Partits'
import { Subtitle, Title, Hr } from '../GPanel/styles'
import { capitalize } from '../../lib/utils'
// import { getColorPartit } from '../../colors'

const IconWrapper = styled.div`
  color: white;
  background: rgba(0, 0, 0, .7);
  border-radius: 4px;
  padding: .4em;
  cursor: pointer;
  margin-left: .5em;
  font-size: 11px;
  font-weight: 100;
`

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const LinkToToggle = styled.a`
  font-size: 11px;
  text-decoration: underline;
  margin-right: 1em;
  margin-bottom: 1em;
  cursor: pointer;
`

const Togglers = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`

const BlockWrapper = styled.div`
  flex-direction: column;
  display: ${({ visible }) => visible ? `flex` : `none`}
`

class Controls extends React.Component {
  state = {
    blocks: [
      { name: 'eleccions', visible: false },
      { name: 'nivells', visible: false },
      { name: 'views', visible: false },
      { name: 'partits', visible: false },
      { name: 'geo', visible: false }
    ]
  }

  toggle = el => this.setState({
    blocks: this.state.blocks.map(b => {
      if (b.name === el) {
        b.visible = !b.visible
      }
      return b
    })
  })

  isVisible = el => this.state.blocks.find(b => b.name === el).visible

  render () {
    return (
      <Consumer>
        {({
          level,
          map,
          mapbox,
          municipi,
          municipis,
          any,
          eleccio,
          changeLocation,
          toggleAny,
          toggleTheme,
          togglePartit,
          toggleMunicipi,
          changeLevel,
          eleccions,
          partits,
          geo,
          toggleGeo,
          toggleAllGeo,
          toggleNoneGeo,
          toggleAllPartits,
          toggleNonePartits,
          exportMap,
          views,
          view,
          toggleView,
          showLegend,
          toggleShowLegend,
          showMap,
          toggleShowMap,
          toggleEleccio,
          toggleEleccioAny,
          selectedPartits
        }) => {
          return (
            <>
              <GPanel>
                <ActionsWrapper>
                  <IconWrapper onClick={exportMap}>
                    {/* <Export size={16} /> */}
                    export
                  </IconWrapper>

                  {/* <IconWrapper onClick={exportMap}>
                    <Save size={16} />
                    save
                  </IconWrapper>

                  <IconWrapper onClick={exportMap}>
                    <Save size={16} />
                    load
                  </IconWrapper> */}
                </ActionsWrapper>

                <Hr />

                {municipis &&
                  <>
                    <Title>Municipis</Title>
                    <Autocomplete
                      initialInputValue={municipi && typeof municipi !== 'undefined' ? municipi.nom : ''}
                      initialSelectedItem={municipi}
                      initialHighlightedIndex={0}
                      selectedItem={municipi}
                      items={municipis}
                      onChange={toggleMunicipi}
                    />
                  </>
                }

                <Hr />

                {eleccions.length &&
                  <>
                    <Title onClick={() => this.toggle('eleccions')}>Eleccions</Title>
                    <BlockWrapper visible={this.isVisible('eleccions')}>
                      <Block>
                        {eleccions.map((eleccio, index) =>
                          <div key={'a' + index}>
                            <Subtitle>
                              <input
                                checked={eleccio.checked === true}
                                type='radio'
                                id={eleccio.nom}
                                value={eleccio.nom}
                                name='sel-eleccio'
                                onChange={toggleEleccio}
                              />
                              {eleccio.nom}
                            </Subtitle>
                            {eleccio.anys.map(any => {
                              const key = `${eleccio.nom}-${any.any}`

                              return (
                                <label htmlFor={key} key={key}>
                                  <input
                                    checked={any.checked === true}
                                    type='radio'
                                    id={key}
                                    value={key}
                                    name='sel-any'
                                    onChange={toggleEleccioAny}
                                  />
                                  <span />
                                  {any.any}
                                </label>
                              )
                            })}
                          </div>
                        )}
                      </Block>
                    </BlockWrapper>
                  </>
                }

                <Hr />

                <Title onClick={() => this.toggle('nivells')}>Nivells</Title>
                <BlockWrapper visible={this.isVisible('nivells')}>
                  <Levels current={level} onChange={changeLevel} />
                </BlockWrapper>

                <Hr />

                <Title onClick={() => this.toggle('views')}>Views</Title>
                <BlockWrapper visible={this.isVisible('views')}>
                  {views.map((v, i) =>
                    <div key={`views-${i}`} className='pretty p-default p-round p-smooth'>
                      <input
                        type='radio'
                        name='views'
                        value={v}
                        id={'views' + capitalize(v)}
                        checked={v === view}
                        onChange={toggleView}
                      />
                      <div className='state p-primary'>
                        <label>
                          {capitalize(v)}
                        </label>
                      </div>
                    </div>
                  )}
                </BlockWrapper>

                <Hr />

                {partits && eleccions.length &&
                  <>
                    <Title onClick={() => this.toggle('partits')}>Partits</Title>
                    <BlockWrapper visible={this.isVisible('partits')}>
                      {!view.includes('partit') &&
                        <Togglers>
                          <LinkToToggle onClick={toggleAllPartits}>All</LinkToToggle>
                          <LinkToToggle onClick={toggleNonePartits}>None</LinkToToggle>
                        </Togglers>
                      }

                      <Block maxHeight={100}>
                        <Partits
                          partits={selectedPartits}
                          any={any}
                          eleccio={eleccio}
                          togglePartit={togglePartit}
                        />
                      </Block>
                    </BlockWrapper>
                  </>
                }

                <Hr />

                {geo &&
                  <>
                    <Title onClick={() => this.toggle('geo')}>Zones</Title>
                    <BlockWrapper visible={this.isVisible('geo')}>
                      <Togglers>
                        <LinkToToggle onClick={toggleAllGeo}>All</LinkToToggle>
                        <LinkToToggle onClick={toggleNoneGeo}>None</LinkToToggle>
                      </Togglers>

                      <Block maxHeight={100}>
                        {geo.features.map(({ properties }, i) =>
                          <div key={`geo-${i}`}>
                            <input
                              type='checkbox'
                              name='partits'
                              value={properties.mundissec}
                              checked={properties.checked}
                              onChange={toggleGeo}
                            />
                            {properties.mundissec}
                          </div>
                        )}
                      </Block>
                    </BlockWrapper>
                  </>
                }

                <Hr />

                <Toggle
                  theme={map.theme}
                  toggle={toggleTheme}
                />

                <div>
                  <ul>
                    <li>
                      <input type='checkbox' name='showLegend' checked={showLegend} onChange={toggleShowLegend} /> legend
                    </li>
                    <li>
                      <input type='checkbox' name='showMap' checked={showMap} onChange={toggleShowMap} /> map
                    </li>
                  </ul>
                </div>
              </GPanel>
            </>
          )
        }}
      </Consumer>
    )
  }
}

export default Controls

import centerOfMass from '@turf/center-of-mass'
import { getColorPartit } from '../colors'

export const filter = ({ selectedPartits, data, eleccio, any }) => {
  return data
    .reduce((acc, row) => {
      const clonedRow = { ...row }
      if (clonedRow._id.eleccio === eleccio && clonedRow._id.any === any) {
        const partits = clonedRow.partits.filter(partit => {
          const selPartit = selectedPartits.find(p => {
            return p.nom === partit.sigles
          })

          return selPartit && selPartit.checked
        })

        clonedRow.partits = partits

        acc.filteredData.push(clonedRow)
        acc.paintedPartits.add(clonedRow.partits[0].sigles)
      }

      return acc
    }, { filteredData: [], paintedPartits: new Set() })
}

export const parseEleccions = eleccions =>
  eleccions.map((e, i) => {
    e.checked = i === 1
    e.anys = e.anys
      .sort((a, b) => b - a)
      .map((a, j) => ({ any: a, checked: i === 1 && j === 0 }))

    return e
  })

export const parseGeo = geo => ({
  type: 'FeatureCollection',
  features: geo.map(g => {
    g.properties.checked = true
    return g
  })
})

export const parsePartits = (partits, view, selectedPartits = undefined) =>
  Object.keys(partits).reduce((uniquePartits, any) => {
    const data = partits[any]

    for (let i = 0; i < data.length; i++) {
      const nom = data[i]
      const index = uniquePartits.findIndex(p => p.nom === nom)

      if (index !== -1) {
        uniquePartits[index].anys.push(any)
      } else {
        const color = getColorPartit(nom)
        let alreadyChecked = true
        if (selectedPartits && selectedPartits.length) {
          const partit = selectedPartits.find(p => p.nom === nom)
          alreadyChecked = partit && partit.checked
        }

        let checked
        if (view.includes('partit')) {
          checked = !uniquePartits.length
        } else {
          checked = alreadyChecked
        }

        uniquePartits.push({ nom, checked, anys: [any], color })
      }
    }

    return uniquePartits
  }, [])

export const getCenter = geo => centerOfMass(geo).geometry.coordinates

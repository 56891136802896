import arrayMove from 'array-move'

const DYNAMIC_MIN_MAX = 20
const NUM_STEPS = 7

export const getData = (filteredData, feature, level, any, eleccions) => {
  const any2 = eleccions.reduce((acc, curr) => {
    if (curr.checked) {
      for (let i = 0; i < curr.anys.length; i++) {
        if (curr.anys[i].checked) {
          acc = curr.anys[i].any
        }
      }
    }
    return acc
  }, null)

  const seccio = Number(feature.properties.seccio)
  const districte = Number(feature.properties.districte)

  let context = []

  if (level === 'seccions') {
    context = filteredData.filter(f => f._id.seccio === seccio && f._id.districte === districte)
  } else if (level === 'districtes') {
    context = filteredData.filter(f => f._id.districte === districte)
  } else if (level === 'municipis') {
    const codiMunicipi = Number(feature.properties.municipi.slice(2, 5))
    context = filteredData.filter(f => f._id.municipi === codiMunicipi)
  }

  return context.find(c => c._id.any === any2)
}

export function moveToTop (id, layer) {
  const { sources } = this.map.getStyle()
  const data = { ...sources[layer.paint.source].data }
  const index = data.features.findIndex(f => Number(f.id) === id)
  const newFeatures = arrayMove(data.features, index, 0)
  data.features = newFeatures
  this.map.getSource(layer.paint.source).setData(data)
}

export const getHeight = context => Math.abs((context.partits[0].vots * 100) / context.totals.votants)

export const mapRank = ({ sigles, percentatgeValids }) => ({ sigles, percentatgeValids })

export const getDynamicSteps = threshold => {
  threshold = threshold < DYNAMIC_MIN_MAX ? DYNAMIC_MIN_MAX : threshold
  const acc = Math.round(threshold / NUM_STEPS)
  const steps = []

  for (let i = 0; i < NUM_STEPS; i++) {
    const v = Math.round((i + 1) * acc)
    const step = i + 1 === NUM_STEPS
      ? threshold
      : v > threshold
        ? threshold
        : v
    steps.push(step)
  }

  return steps
}

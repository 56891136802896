import React from 'react'
import Downshift from 'downshift'
import styled from 'styled-components'

const Ul = styled.ul`
  width: calc(100% + 2em);
  background: white;
  max-height: 250px;
  height: auto;
  flex-grow: 1;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1;
  top: 30px;
  margin-left: -1em;
  padding-bottom: 1em;
  box-shadow: 0 15px 30px 0 rgba(0,0,0,0.11), 0 5px 15px 0 rgba(0,0,0,0.08);
`

const Li = styled.li`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 1em;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
${({ isHighlighted, isSelected }) =>
    `
    background-color: ${isHighlighted ? 'rgba(0, 0, 0, .05)' : 'white'};
    font-weight: ${isSelected ? 'bold' : 'normal'};
    `
}
`

const InputWrapper = styled.div`
  display: flex;
  flex-grow: 1;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-wrap: wrap;
`

const Input = styled.input`
  outline: none;
  border: 0;
  cursor: text;
  margin-right: 0;
  display: flex;
  flex-grow: 1;
  padding: .5em 0 0 0;
`

const BigWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

export default props =>
  <BigWrapper>
    <Downshift
      onChange={props.onChange}
      initialInputValue={props.initialInputValue}
      initialSelectedItem={props.initialSelectedItem}
      initialHighlightedIndex={props.initialHighlightedIndex}
      itemToString={item => (item && item.nom) || ''}>
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        getRootProps,
        isOpen,
        inputValue,
        selectedItem,
        highlightedIndex
      }) => (
        <Wrapper {...getRootProps({ refKey: 'ref' })}>
          <InputWrapper>
            <Input {...getInputProps()} />
          </InputWrapper>
          { isOpen &&
            <Ul {...getMenuProps({ isOpen })}>
              {isOpen && inputValue.length >= 3
                ? props.items
                  .filter(item => !inputValue || (item.nom && item.nom.toLowerCase().includes(inputValue.toLowerCase())))
                  .map((item, index) => (
                    <Li
                      {...getItemProps({
                        key: item.nom,
                        index,
                        item
                      })}
                      isHighlighted={highlightedIndex === index}
                      isSelected={selectedItem && selectedItem.nom === item.nom}
                    >
                      {item.nom}
                    </Li>
                  ))
                : null}
            </Ul>
          }
        </Wrapper>
      )}
    </Downshift>
  </BigWrapper>

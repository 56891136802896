import React from 'react'
import styled from 'styled-components'

import { Subtitle } from '../GPanel/styles'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 220px;
  position: absolute;
  bottom: 2em;
  left: 2em;
  background-color: white;
  border-radius: 4px;
  padding: 1em;
`

const Inner = styled.div`
  display: flex;
  width: 100%;
`

const LegendWrapper = ({ title, children }) =>
  <Wrapper id='legend'>
    <Subtitle>{title || 'Llegenda'}</Subtitle>
    <Inner>
      {children}
    </Inner>
  </Wrapper>

export default LegendWrapper

import fetch from 'universal-fetch'
import { stringify as urlify } from 'querystring'
import { log } from './utils'

const { REACT_APP_API_URL } = process.env

export default (endpoint, vars) => {
  const url = `/api/${endpoint}${vars && Object.keys(vars).length > 0 ? `?${urlify(vars)}` : ''}`
  log(`⚙️ API ${url}`)
  return fetch(REACT_APP_API_URL + url).then(response => response.json())
}

import React, { memo } from 'react'
import styled from 'styled-components'

import { getColorPartit } from '../../colors'

const Bubble = styled.div`
  width: 200px;
  height: 90px;
  display: flex;
  flex-direction: column;
  font-family: 'Exo 2', sans-serif;
  padding: 1em;

  p {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    margin-bottom: 10px;

    span {
      display: flex;
      align-content: flex-end;
      margin-left: auto;
      font-weight: 700;
    }
  }
`

const Dot = styled.i`
  display: flex;
  width: 14px;
  height: 14px;
  border-radius: 14px;
  margin-right: 5px;
  ${({ color }) => `background-color: ${color}`}
`

const renderPartits = partits =>
  partits.map((partit, i) =>
    <p key={i}>
      <Dot color={`rgba(${getColorPartit(partit.sigles).join(',')},1)`} />
      {partit.sigles}
      <span>{partit.percentatgeValids}%</span>
    </p>
  )

export default memo(({ seccio, districte, data }) => {
  return (
    <Bubble>
      <p>Secció <span>{seccio}</span></p>
      <p>Districte <span>{districte}</span></p>
      <hr />
      {data && renderPartits(data)}
    </Bubble>
  )
})

import React from 'react'
import { capitalize } from '../../../lib/utils'
import { levels } from '../../../state'

export default ({ current, onChange }) =>
  levels.map(level =>
    <div key={level} className='pretty p-default p-round p-smooth'>
      <input
        onChange={onChange}
        type='radio'
        name='viewBy'
        value={level}
        id={'viewBy' + capitalize(level)}
        checked={current === level}
        disabled={current === 'municipis' && level !== 'municipis'}
      />
      <div className='state p-primary'>
        <label>{capitalize(level)}</label>
      </div>
    </div>
  )

import { badalona } from './lib/utils'

export const levels = [
  'seccions',
  'districtes'
  // 'municipis'
]

const MODE = 'badalona'
const DEFAULT_MODE = 'badalona'

const mode = (type, state, withPanel = false) => {
  const mode = Object.keys(modes).indexOf(type) === -1
    ? DEFAULT_MODE
    : type

  const partialState = withPanel
    ? { ...modes[mode], selectedGeo: 8015506001, level: 'seccions' }
    : { ...modes[mode] }

  return {
    ...state,
    ...partialState
  }
}

const modes = {
  badalona: {
    municipi: badalona,
    level: 'seccions',
    selectedGeo: null
  },
  municipis: {
    municipi: null,
    level: 'municipis',
    selectedGeo: null
  }
}

export default mode(MODE, {
  mapbox: null,
  map: {
    theme: 'light',
    center: null,
    bounds: null,
    loaded: false
  },
  any: 2015,
  // level: 'municipis', // [seccions, districtes, municipis]
  municipis: [],
  layers: [],
  eleccions: [],
  districtes: [],
  repaint: false,
  compute: false,
  fetch: false,
  loading: false,
  mounted: false,
  eleccio: 'municipals',
  views: [
    'global',
    // 'partit',
    'partit2'
  ],
  view: 'global',
  partits: undefined,
  paintedPartits: [],
  selectedPartits: [],
  data: [],
  filteredData: [],
  levels,
  saved: [],
  showLegend: true,
  showMap: true
})

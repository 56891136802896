import React from 'react'
import styled from 'styled-components'

import Animation from './Animation'
import Courtain from './Courtain'

const AnimationWrapper = styled.div`
  max-width: 120px;
  display: flex;
`

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  

${({ inner }) => inner
    ? `
    width: 150px;
    height: 150px;
    border-radius: 35px;
    z-index: 1;
    background-color: rgba(255, 255, 255, .7);
    `
    : `
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, .03);
    `
}
`

const Message = styled.div`
  margin-top: 1em;
`

export default ({ inner, msg }) =>
  <Courtain inner>
    <LoadingWrapper inner={inner}>
      <AnimationWrapper>
        <Animation />
      </AnimationWrapper>
      { msg && (
        <Message>
          <strong>{msg}</strong>
        </Message>
      )}
    </LoadingWrapper>
  </Courtain>

import React from 'react'
import styled from 'styled-components'

const Alert = styled.div`
  width: 90vw;
  padding: 1em;
  border: 1px solid red;
`
// eslint-disable-next-line
export default ({ error, info }) =>
  <Alert>
    <span role='img' aria-label='warning'>⚠️</span> Hem tingut un error!
  </Alert>

import React, { createRef, Component } from 'react'
import { ThemeProvider } from 'styled-components'
import * as Sentry from '@sentry/browser'

import { Consumer } from './context'
import { GlobalStyles } from './styles'
import views from './views'

// import Controls from './components/Controls/'
import Map from './components/Map'
// import Panel from './components/Panel'
import Loading from './components/Loading'
import Alert from './components/Alert'
import Controls from './components/Controls'
import Legend from './components/Controls/Legend'
import { isDev } from './lib/utils'
// import Modal from './components/Modal'
// import Notification from './components/Notification'

const IS_DEV = isDev()

if (!IS_DEV) {
  Sentry.init({
    dsn: 'https://eac8b5991dfe4ec09c40956a55c187e8@sentry.io/1418293'
  })
}

class App extends Component {
  mapRef = createRef()
  panelRef = createRef()

  state = { error: null }

  componentDidCatch (error, info) {
    if (!this.state.error) { // Prevent loop
      console.error(error)
      console.error(info)

      this.setState({ error: { error, info } })

      if (!IS_DEV) {
        Sentry.withScope(scope => {
          Object.keys(info).forEach(key => {
            scope.setExtra(key, info[key])
          })
          Sentry.captureException(error)
        })
      }
    }
  }

  render () {
    const { error } = this.state
    return (
      <>
        <GlobalStyles />
        <Consumer>
          {({
            toggleTheme,
            changeLevel,
            partits,
            eleccions,
            level,
            loading,
            selectedGeo,
            mounted,
            map,
            municipis,
            municipi,
            compute,
            view,
            showLegend,
            filteredData,
            paintedPartits,
            selectedPartits
          }) => {
            if ((loading && !mounted) || !mounted) {
              return <Loading msg={'Carregant ...'} />
            }

            if (error) {
              return (
                <>
                  <Alert {...error} />
                  {!IS_DEV && <span onClick={() => Sentry.showReportDialog()}>Report feedback</span>}
                </>
              )
            }

            let LegendContent
            if (views[view].Legend) {
              LegendContent = views[view].Legend
            }

            return (
              <ThemeProvider theme={{ mode: map.theme }}>
                <>
                  <div style={{ width: '100vw', height: '100vh' }}>
                    { map.center && <Map el={this.mapRef} /> }
                    <Controls mapRef={this.mapRef} />

                    { paintedPartits.size > 0 && showLegend && partits && LegendContent &&
                      <Legend>
                        <LegendContent paintedPartits={paintedPartits} filteredData={filteredData} partits={selectedPartits} />
                      </Legend>
                    }
                  </div>
                  { loading && <Loading msg={'Carregant ...'} inner />}
                  {/* <Modal>
                    <h1>Aixo es un modal!</h1>
                    <p>asdaosdasd</p>
                  </Modal> */}
                  {/* <Notification>
                    Aixo es una notificacio!
                  </Notification> */}
                </>
              </ThemeProvider>
            )
          }}
        </Consumer>
      </>
    )
  }
}

export default App

import React from 'react'
import { renderToString } from 'react-dom/server'

import Bubble from './Bubble'
import { moveToTop } from '../utils'

export default {
  outterClick (layer, e) {
    const { selectedGeo, dispatch } = this.props
    const [isInLayer] = this.map.queryRenderedFeatures(e.point, { layers: [layer.paint.id] })

    if (!isInLayer && selectedGeo) {
      this.map.setFeatureState({ source: layer.paint.source, id: selectedGeo }, { selected: false })
      dispatch({ type: 'CLEAR_POLYGON' })
    }
  },
  mousemove (layer, e) {
    if (!layer.paint) {
      return
    }

    const { id, properties } = this.map.queryRenderedFeatures(e.point, { layers: [layer.paint.id] })[0]

    if (!id) {
      return
    }

    if (id === this.hoverId) {
      this.popup.setLngLat([e.lngLat.lng, e.lngLat.lat])
      return
    }

    moveToTop.call(this, id, layer)

    if (id) {
      if (this.hoverId) {
        this.map.setFeatureState({ source: layer.paint.source, id: this.hoverId }, { hover: false })
        this.popup.remove()
      }
      this.hoverId = id

      this.map.setFeatureState({
        source: layer.paint.source,
        id: this.hoverId
      },
      { hover: true })

      const rank = JSON.parse(properties.rank)

      this.popup.setLngLat([e.lngLat.lng, e.lngLat.lat])
        .setHTML(
          renderToString(
            <Bubble
              level={this.props.level}
              partits={this.props.selectedPartits}
              seccio={properties.seccio}
              districte={properties.districte}
              data={rank} />
          )
        )
        .addTo(this.map)
    }
  },
  mouseleave (layer, e) {
    if (this.hoverId) {
      this.map.setFeatureState({ source: layer.paint.source, id: this.hoverId }, { hover: false })
    }

    this.hoverId = null
    this.popup.remove()
  },
  click (layer, e) {
    let { selectedGeo, dispatch } = this.props
    const data = this.map.queryRenderedFeatures(e.point)[0]
    moveToTop.call(this, data.id, layer)

    if (selectedGeo) {
      let bkup = selectedGeo
      this.map.setFeatureState({ source: layer.paint.source, id: bkup }, { selected: false })

      dispatch({ type: 'CLEAR_POLYGON' })

      if (data.id === bkup) {
        return
      }
    }

    selectedGeo = data.id

    this.map.setFeatureState({ source: layer.paint.source, id: selectedGeo }, { selected: true })

    dispatch({ type: 'CLICK_POLYGON', selectedGeo })
  }
}

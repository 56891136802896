export const badalona = {
  nom: 'Badalona',
  codi: '080155'
}

export const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1)

export const formatCodi = codi => {
  if (typeof codi !== 'number') {
    return codi
  }

  codi = codi.toString()
  codi = codi.length < 6 ? '0' + codi : codi
  return codi
}

export const isDev = () => process.env.NODE_ENV === 'development'

export const log = str => isDev() && console.log(str)

export const getData = ({ layers, selectedGeo }) =>
  layers[0].sourceData.data.features.find(f =>
    Number(f.id) === selectedGeo
  )

export const uncamelize = (text, separator = ' ') =>
  text
    .replace(/[A-Z]/g, letter => separator + letter.toLowerCase())
    .replace('/^' + separator + '/', '')

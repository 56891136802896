import { levels } from '../state'
import { getFillColors } from '../colors'
import { log } from '../lib/utils'
import { parsePartits } from './utils'

export default (state, { type, ...rest }) => {
  log(`🔥ACTION: ${type}`)

  if (type === 'TOGGLE_ELECCIO') {
    const eleccions = []
    let any = state.any

    for (let i = 0; i < state.eleccions.length; i++) {
      const eleccio = { ...state.eleccions[i] }
      if (eleccio.nom === rest.eleccio) {
        eleccio.checked = true
        eleccio.anys = eleccio.anys.map(a => ({ ...a, checked: false }))
        eleccio.anys[0].checked = true
        any = eleccio.anys[0].any
      } else {
        eleccio.checked = false
        eleccio.anys = eleccio.anys.map(a => ({ ...a, checked: false }))
      }
      eleccions.push(eleccio)
    }

    const selectedPartits = parsePartits(state.partits[rest.eleccio], state.view)

    return { ...state, selectedPartits, eleccions, eleccio: rest.eleccio, any, compute: true }
  }

  if (type === 'TOGGLE_ELECCIO_ANY') {
    const eleccions = []
    let [nom, any] = rest.any.split('-')
    any = Number(any)

    for (let i = 0; i < state.eleccions.length; i++) {
      const eleccio = { ...state.eleccions[i] }
      if (eleccio.nom === nom) {
        eleccio.checked = true
        eleccio.anys = eleccio.anys.map(a => ({ ...a, checked: a.any === any }))
      } else {
        eleccio.checked = false
        eleccio.anys = eleccio.anys.map(a => ({ ...a, checked: false }))
      }
      eleccions.push(eleccio)
    }

    const selectedPartits = parsePartits(state.partits[nom], state.view)

    return { ...state, selectedPartits, eleccions, eleccio: nom, any, compute: true }
  }

  if (type === 'TOGGLE_SHOWMAP') {
    const showMap = !state.showMap

    state.mapbox.getStyle().layers
      .filter(l => !l.id.includes('TOC-'))
      .map(l => state.mapbox.setLayoutProperty(l.id, 'visibility', showMap ? 'visible' : 'none'))

    return { ...state, showMap }
  }

  if (type === 'TOGGLE_VIEW') {
    /*
    Si view es partit: - Pillar el 1er que hi hagi checked
    Si es global: Tots checked
    */
    let checked = false
    const selectedPartits = []
    const viewIsPartit = rest.view.includes('partit')
    for (let i = 0; i < state.selectedPartits.length; i++) {
      const partit = { ...state.selectedPartits[i] }
      if (viewIsPartit) {
        partit.checked = !checked && partit.checked
        if (partit.checked) {
          checked = true
        }
      } else {
        partit.checked = true
      }

      selectedPartits.push(partit)
    }

    console.log('acabo', viewIsPartit, selectedPartits)

    return { ...state, selectedPartits, view: rest.view, compute: true }
  }

  if (type === 'SET_MAP') {
    return { ...state, mapbox: rest.map }
  }

  if (type === 'TOGGLE_ALL_PARTITS') {
    const selectedPartits = [...state.selectedPartits].map(p => {
      p.checked = true
      return p
    })
    return { ...state, selectedPartits, compute: true }
  }

  if (type === 'TOGGLE_NONE_PARTITS') {
    const selectedPartits = [...state.selectedPartits].map(p => {
      p.checked = false
      return p
    })

    selectedPartits[0].checked = true

    return { ...state, selectedPartits, compute: true }
  }

  if (type === 'TOGGLE_PARTIT') {
    const selectedPartits = [...state.selectedPartits].map(partit => {
      if (partit.nom === rest.partit) {
        partit.checked = !partit.checked
      } else if (state.view.includes('partit')) {
        partit.checked = false
      }

      return partit
    })

    return { ...state, selectedPartits, compute: true }
  }

  if (type === 'TOGGLE_ALL_GEO') {
    const geo = [...state.geo].map(g => {
      g.checked = true
      return g
    })
    return { ...state, geo, compute: true }
  }

  if (type === 'TOGGLE_NONE_GEO') {
    const geo = [...state.geo].map(g => {
      g.checked = false
      return g
    })

    geo[0].checked = true

    return { ...state, geo, compute: true }
  }

  if (type === 'TOGGLE_GEO') {
    const features = []

    for (let g of state.geo.features) {
      const feature = { ...g }

      if (feature.properties.mundissec === rest.mundissec) {
        feature.properties.checked = !feature.properties.checked
      }

      features.push(feature)
    }

    return { ...state, geo: { ...state.geo, features }, compute: true }
  }

  if (type === 'TOGGLE_MAP_LOADED') {
    return { ...state, map: { ...state.map, loaded: !state.map.loaded } }
  }

  if (type === 'TOGGLE_LOADING') {
    return { ...state, loading: !state.loading }
  }

  if (type === 'TOGGLE_REPAINT') {
    return { ...state, repaint: !state.repaint }
  }

  if (type === 'TOGGLE_LEVEL') {
    const level = levels.indexOf(rest.level) > -1 ? rest.level : 'seccions'
    return { ...state, level, fetch: true }
  }

  if (type === 'CLICK_POLYGON') {
    return { ...state, selectedGeo: rest.selectedGeo }
  }

  if (type === 'CLEAR_POLYGON') {
    return { ...state, selectedGeo: null }
  }

  if (type === 'TOGGLE_THEME') {
    const theme = state.map.theme === 'dark' ? 'light' : 'dark'
    return { ...state, map: { ...state.map, theme, loaded: false } }
  }

  if (type === 'TOGGLE_ANY') {
    const layers = [...state.layers]
    const features = getFillColors(layers[0].sourceData.data.features, rest.any)
    layers[0].sourceData.data.features = features
    return { ...state, any: Number(rest.any), layers }
  }

  if (type === 'TOGGLE_MUNICIPI') {
    const municipi = state.municipis.find(m => m.codi === rest.municipi.codi)
    return { ...state, municipi, fetch: true }
  }

  if (type === 'UPDATE_DATA') {
    let { municipi, level, selectedGeo } = state
    // const layers = rest.layers
    //   ? rest.layers
    //   // : selectedData
    //   //   ? selectedData.layers
    //   : state.layers

    if (selectedGeo && rest.municipi && rest.municipi.codi !== state.municipi.codi) {
      selectedGeo = null
    } else if (selectedGeo && state.layers.length) {
      const oldData = state.layers[0].sourceData.data.features.find(f => Number(f.id) === selectedGeo)

      if (level === 'seccions') {
        selectedGeo = Number(oldData.properties.mundissec)
        // selectedData = layers[0].sourceData.data.features.find(f => Number(f.properties.mundissec) === selectedGeo)
      }

      if (level === 'districtes') {
        selectedGeo = Number(oldData.properties.municipi + oldData.properties.districte)
        // selectedData = layers[0].sourceData.data.features.find(f => Number(f.properties.municipi + f.properties.districte) === selected)
      }

      if (level === 'municipis') {
        municipi = null
        selectedGeo = null
        // selectedData = null
        // selected = Number(oldData.properties.municipi)
        // selectedData = layers[0].sourceData.data.features.find(f => Number(f.properties.municipi) === selectedGeo)
      }
    }

    const map = rest.map || {}

    return {
      ...state,
      municipi,
      selectedGeo,
      // selectedData,
      mounted: true,
      compute: false,
      fetch: false,
      ...rest,
      map: {
        ...state.map,
        ...map
      }
    }
  }
}

import React from 'react'

import { Wrapper } from './styles'

const Panel = props =>
  <Wrapper>
    {props.children}
  </Wrapper>

export default Panel

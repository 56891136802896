import convert from 'color-convert'

import { getData, mapRank, getDynamicSteps } from '../utils'
import { generatePaletteFromColor, formatHSL } from '../../colors'

const transform = (layerId, { geo, filteredData, any, level, municipi, selectedPartits, eleccions }) => {
  const transformed = []
  const threshold = Math.round(Math.max(...filteredData.map(r => (r.partits.length && r.partits[0].percentatgeValids) || 0)))
  const steps = getDynamicSteps(threshold)

  for (const feature of geo.features) {
    const data = getData(filteredData, feature, level, any, eleccions)

    if (!data.partits.length) continue

    const properties = {}
    const colorPartit = selectedPartits.find(p => p.nom === data.partits[0].sigles).color
    const colorHSL = convert.rgb.hsl(colorPartit)
    const colors = generatePaletteFromColor(colorHSL)
    const percentatge = data.partits[0].percentatgeValids
    const index = percentatge >= threshold ? steps.length - 1 : steps.findIndex(step => percentatge <= step)
    const color = formatHSL(colors[index])

    if (data) {
      properties.rank = data.partits.slice(0, 3).map(mapRank)
      properties.color = color
    }

    transformed.push({
      id: feature.id,
      type: 'Feature',
      geometry: { ...feature.geometry },
      properties: { ...feature.properties, ...properties }
    })
  }

  return transformed
}

export default transform

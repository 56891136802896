import { css, createGlobalStyle } from 'styled-components'
import theme from 'styled-theming'
import reset from 'styled-reset'
import 'pretty-checkbox/src/pretty-checkbox.scss'

const SIZES = {
  desktop: 992,
  tablet: 768,
  mobile: 468
}

const bgColor = theme('mode', { light: 'white', dark: '#222' })

export const small = css`
  font-size: 10px;
  text-transform: uppercase;
  color:  rgba(0, 0, 0, .3);
`

export const GlobalStyles = createGlobalStyle`
  ${reset}
  
  html {
    height: 100%
  }

  *, body {
    font-size: 15px
    font-family: 'Exo 2', sans-serif;
  }

  body {
    background-color: ${bgColor}
    transition: background-color .2s ease-in
  }

  select, input, textarea {
    font-size: 15px
  }

  h1, h2, h3, h4, h5, h6, h7 {
    font-family: 'Exo 2', sans-serif
    font-weight: 700 
  }

  strong {
    font-weight: 700 
  }

  span.small {
    ${small}
  }
`

export const media = Object.keys(SIZES).reduce((acc, size) => {
  acc[size] = (...args) => css`
    @media screen and (min-width: ${(SIZES[size] / 16)}em) {
      ${css(...args)}
    }
  `
  return acc
}, {})

import { getData, mapRank } from '../utils'

const transform = (layerId, { geo, filteredData, any, level, municipi, selectedPartits, eleccions }) => {
  const transformed = []

  for (const feature of geo.features) {
    const data = getData(filteredData, feature, level, any, eleccions)
    const properties = {}

    if (data && data.partits.length) {
      properties.rank = data.partits.slice(0, 3).map(mapRank)
      const partit = selectedPartits.find(p => p.nom === data.partits[0].sigles)
      const color = partit.color
      properties.color = `rgba(${color.join(',')},1)`
    }

    transformed.push({
      id: feature.id,
      type: 'Feature',
      geometry: { ...feature.geometry },
      properties: { ...feature.properties, ...properties }
    })
  }

  return transformed
}

export default transform

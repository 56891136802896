import React, { Component } from 'react'
import styled from 'styled-components'
import mapboxgl from 'mapbox-gl'

import { mapStyle } from './utils'
import { Consumer } from '../../context'

mapboxgl.accessToken = 'pk.eyJ1IjoibWFuZWxnYXJjaWEiLCJhIjoiY2pwbnlhenN1MDdobjQzcDEydmVjcnFsZyJ9.E9yNmFafgJOJMiM7mY8zDg'
// TOKEN FROM: https://analisi.transparenciacatalunya.cat/d/t4qw-gx3q/visualization
// pk.eyJ1Ijoic29jcmF0YSIsImEiOiJjaW45aHg1NnMwNDJudHpseWljOTBvMzl3In0.ZvwhmPuu21nkn5HL4r2A-Q

const MapWrapper = styled.div`
  width: 100vw;
  height: 100vh;
`

const MapConsumer = props =>
  <Consumer>
    {ctx =>
      <Map {...props} {...ctx} />
    }
  </Consumer>

class Map extends Component {
  constructor (props) {
    super(props)

    this.map = null // Mapxbox map
    this.popup = new mapboxgl.Popup({ closeButton: false, closeOnClick: false }) // Recycled poup
    this.state = { loaded: false }
    this.hoverId = null
  }

  removeLayers = () => {
    const { layers } = this.map.getStyle()

    if (layers && layers.length) {
      layers
        .filter(layer => layer.id.includes('TOC-'))
        .map(layer => {
          this.map.removeLayer(layer.id)
          return layer
        })
        .map(layer => {
          if (this.map.getSource(layer.source)) {
            this.map.removeSource(layer.source)
          }
          return layer
        })
    }

    // const buildingsLayer = this.map.getLayer('3d-buildings')
    // if (buildingsLayer) {
    //   this.map.removeLayer('3d-buildings')
    // }
  }

  createLayers = () => {
    const { layers } = this.props

    for (let i = 0; i < layers.length; i++) {
      const layer = { ...layers[i] }
      const { data, paint } = layer
      const source = this.map.getSource(paint.source)

      if (!source) {
        this.map.addSource(paint.source, { type: 'geojson', data })
      }

      this.map.addLayer(paint)

      if (layer.events) {
        this.bindEvents(layer)
      }
    }

    // Create buildings layer
    // this.map.addLayer(
    //   buildingsLayerData,
    //   getLabelId(this.map.getStyle().layers)
    // )
  }

  callLayersMapClick = e => {
    const { layers } = this.props
    for (let i = 0; i < layers.length; i++) {
      const layer = layers[i]

      if (layer.events && layer.events.outterClick && typeof layer.events.outterClick === 'function') {
        layer.events.outterClick.call(this, layer, e)
      }
    }
  }

  onMapClick = e => {
    this.callLayersMapClick(e)
  }

  bindEvents = layer => {
    const events = Object.keys(layer.events)

    for (let y = 0; y < events.length; y++) {
      const event = events[y]

      if (typeof layer.events[event] === 'function' && event !== 'outterClick') {
        this.map.on(event, layer.paint.id, layer.events[event].bind(this, layer))
      }
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const { any, dispatch, repaint, map: { theme, bounds } } = this.props
    const { loaded } = this.state

    const anyChanged = any !== prevProps.any
    const mapLoaded = loaded && !prevState.loaded
    const needRepaint = loaded && repaint && !prevProps.repaint
    const themeChanged = theme !== prevProps.map.theme

    if (themeChanged) {
      this.map.setStyle(mapStyle(theme))
    }

    if (loaded && (themeChanged || anyChanged || mapLoaded || needRepaint)) {
      // const options = { bearing: level === 'municipis' ? 0 : -60 }

      this.removeLayers()
      this.createLayers()
      this.map.fitBounds(bounds, {})

      // if (selectedGeo) {
      //   this.map.setFeatureState({ source: layers[0].paint.source, id: selectedGeo }, { selected: true })
      // }

      if (needRepaint) {
        dispatch({ type: 'TOGGLE_REPAINT' })
      }
    }
  }

  componentDidMount () {
    const { dispatch, map: { center, theme } } = this.props
    const { el } = this.props

    this.map = new mapboxgl.Map({
      container: el.current,
      style: mapStyle(theme),
      center,
      zoom: 13,
      preserveDrawingBuffer: true
      // pitch: 60,
      // bearing: -60
    })

    // Disable double click zoom
    this.map.doubleClickZoom.disable()

    this.map.on('click', this.onMapClick)

    this.map.on('style.load', () => {
      if (this.state.loaded) {
        dispatch({ type: 'TOGGLE_REPAINT' })
      }

      if (!this.state.loaded) {
        dispatch({ type: 'SET_MAP', map: this.map })
        this.setState({ loaded: true })
      }
    })
  }

  render () {
    const { el } = this.props

    return (
      <MapWrapper ref={el} />
    )
  }
}

export default MapConsumer

import styled, { css } from 'styled-components'

export const Hr = styled.hr`
  height: 1px;
  border: 0px;
  width: 100%;
  background-color: rgba(0, 0, 0, .15);
  margin: 1em 0 1em 0;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  background: white;
  // bottom: 2em;
  padding: 2em;
  width: 15vw;
  min-width: 250px;

  position: absolute;
  top: 3em;
  bottom: 3em;
  padding: 1em;
  border-radius: 14px 0 0 14px;
  box-shadow: 0 15px 30px 0 rgba(0,0,0,0.11),
              0 5px 15px 0 rgba(0,0,0,0.08);
`

const CommonHeader = css`
  margin: .8em 0 .8em 0;
  text-transform: uppercase;
`

export const Title = styled.h2`
  ${CommonHeader}
  font-size: 13px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
`

export const Subtitle = styled.h3`
  ${CommonHeader}
  font-size: 12px;
  color: rgba(0, 0, 0, .4);
`

import React, { memo } from 'react'

function Partits ({ partits, any, eleccio, togglePartit }) {
  return (
    <div>
      {partits.map(({ anys, nom, checked }, i) => {
        if (anys.includes(any.toString())) {
          return (
            <div key={`partit-${i}`}>
              <input
                type='checkbox'
                name='partits'
                value={nom}
                checked={checked}
                onChange={togglePartit}
              />
              {nom}
            </div>
          )
        } else {
          return undefined
        }
      }).filter(p => typeof p !== 'undefined')}
    </div>
  )
}

export default memo(Partits)

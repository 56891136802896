import React from 'react'
import styled from 'styled-components'

const CommonDiv = styled.div`
  display: flex;
  flex-direction: column;
`

export const Wrapper = styled(CommonDiv)`
 max-height: ${({ maxHeight }) => `${maxHeight || 300}px;`}
 overflow: hidden;
`

export const Inner = styled(CommonDiv)`
  overflow-y: scroll;
  width: 100%;
`

const Block = ({ maxHeight, children }) =>
  <Wrapper maxHeight={maxHeight}>
    <Inner>
      { children || null }
    </Inner>
  </Wrapper>

export default Block

// import { getHeight } from '../utils'
// import { getColorPartit } from '../../colors'
// import { levels } from '../../state'
import { getCenter } from '../../context/utils'
import { getData } from '../utils'
import { getColorPartit, randomColor } from '../../colors'

const MAX_RADIUS = 50

const mapPolygonFeatures = coords => ({ geometry: { type: 'Polygon', coordinates: [coords] } })

const transform = (layerId, { geo, filteredData, any, level, municipi }) => {
  const transformed = []

  for (const feature of geo.features) {
    const features = feature.geometry.coordinates.map(mapPolygonFeatures)
    const coordinates = getCenter({ type: 'FeatureCollection', features })
    const data = getData(filteredData, feature, level, any)
    const radius = layerId === 'TOC-partit'
      ? Number(((data.partits[0].vots * MAX_RADIUS) / data.totals.votsValids).toFixed(2))
      : MAX_RADIUS
    const color = `rgba(${data ? getColorPartit(data.partits[0].sigles) : randomColor()},1)`

    transformed.push({
      type: 'Feature',
      id: feature.id,
      properties: { ...feature.properties, color, radius },
      geometry: { type: 'Point', coordinates }
    })
  }

  return transformed
}

export default transform

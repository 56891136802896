export default [
  {
    config: {
      transform: true,
      events: false
    },
    id: 'TOC-partit2',
    type: 'fill',
    source: 'partit2-source',
    paint: {
      'fill-color': ['get', 'color'],
      'fill-opacity': ['case',
        ['boolean', ['feature-state', 'hover'], false], 1,
        ['boolean', ['feature-state', 'selected'], false], 1,
        1
      ]
    }
  },
  {
    id: 'TOC-patit2-linies',
    type: 'line',
    source: 'partit2-source',
    paint: {
      'line-color': ['case',
        ['boolean', ['feature-state', 'hover'], false], 'yellow',
        ['boolean', ['feature-state', 'selected'], false], 'yellow',
        'white'
      ],
      'line-width': 2
    }
  }
]

import React from 'react'
import Toggle from 'react-toggle'
import styled from 'styled-components'
import theme from 'styled-theming'
import { media } from '../../../styles'

import 'react-toggle/style.css'

const bgColor = theme('mode', {
  dark: 'rgba(0, 0, 0, .1)',
  light: 'rgba(0, 0, 0, .7)'
})

const iconColor = theme('mode', {
  dark: 'rgba(0, 0, 0, .7)',
  light: 'rgba(255, 255, 255, 1)'
})

const ToggleWrapper = styled.div`
  display: flex

  // ${media.desktop`
  //   bottom: auto
  //   top: 2em
  // `}

  .toggle {
    .react-toggle-track {
      background-color: ${bgColor} !important

      span {
        color: ${iconColor} !important
      }
    }

    .react-toggle-thumb {
      border-color: ${bgColor} !important
    }
  }
`

export default ({ theme, toggle }) =>
  <ToggleWrapper>
    <Toggle
      id='theme'
      className='toggle'
      icons={{
        checked: <span className='fas fa-moon fa-xs' />,
        unchecked: <span className='fas fa-sun fa-xs' />
      }}
      defaultChecked={theme === 'light'}
      aria-labelledby='theme-label'
      onChange={toggle}
    />
  </ToggleWrapper>

import React from 'react'
import styled from 'styled-components'

const Color = styled.div`
  display: flex;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: ${({ color }) => `rgba(${color.join(',')},1);`}
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  font-size: 11px;

  &:last-of-type {
    margin-bottom: 0px;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  // max-height: 200px;
  overflow: hidden;
`

const Inner = styled.div`
  overflow-y: scroll;
  width: 100%;
`

const Legend = ({ paintedPartits, partits }) =>
  <Wrapper>
    <Inner>
      {partits.map((partit, i) =>
        paintedPartits.has(partit.nom) && <Row key={`legend-global-${i}`}>
          <Color color={partit.color} />
          {partit.nom}
        </Row>
      )}
    </Inner>
  </Wrapper>

export default Legend

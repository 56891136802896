const randomValue = () => Math.round(Math.random() * 255)
export const randomColor = () => {
  let unique = false
  const colors = Object.keys(COLORS).map(p => COLORS[p])
  let newColor = null

  while (!unique) {
    const c = [randomValue(), randomValue(), randomValue()]

    if (!colors.includes(c)) {
      newColor = c
      unique = true
    }
  }

  return newColor
}

const COLORS = {
  'PP': [0, 0, 255],
  'BADALONA EN COMÚ-PA': [128, 0, 128],
  'PSC-CP': [255, 0, 0],
  'PSC-PM': [255, 0, 0],
  'ICV-EUA-EP-E': [0, 128, 0],
  'ERC-A-MES-AM': [255, 255, 0],
  'ERC-AM': [255, 255, 0],
  'C\'s': [255, 165, 0],
  'CiU': [173, 216, 230],
  'UPyD': [255, 0, 255],
  'LLEI': [139, 0, 0],
  'FEM B, PMB': [255, 255, 255],
  'CUP-PA': [128, 128, 0]
}

export const getColorPartit = sigles => COLORS[sigles] || randomColor()

export const getFillColors = (features, any) => {
  return features.map(f => {
    try {
      const context = f.properties.context.find(c => c._id.any === Number(any))
      f.properties.color = `rgba(${getColorPartit(context.partits[0].sigles).join(',')},1)`
    } catch (err) {
      console.warn(`No data for: districte: ${f.properties.districte} /  seccio: ${f.properties.seccio}  any: ${any}`)
    }

    return { ...f }
  })
}

export const formatHSL = color => `hsl(${color[0]}, ${color[1]}%, ${color[2]}%)`

export const generatePaletteFromColor = color => {
  const colors = []

  for (let i = 1; i < 8; i++) {
    colors.push([color[0], color[1], Math.round(i * 12)])
  }

  return colors.reverse()
}

export const darkOrLight = lightness => lightness <= 50 ? 'white' : 'black'
